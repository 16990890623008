import axios from "axios"
import {
  EmailAuthProvider,
  createUserWithEmailAndPassword,
  getIdTokenResult,
  onAuthStateChanged,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updateEmail,
  updatePassword,
  updateProfile,
  deleteUser,
  confirmPasswordReset,
} from "firebase/auth"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useMemo, useState } from "react"
import {
  auth,
  getUserDoc,
  createUserDoc,
  updateUserDoc,
} from "../services/firebase"
import onboardingSteps from "../utils/onboardingStep"

export const AuthContext = React.createContext()

export function useUser() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState({})
  const [booting, setBooting] = useState(true)
  const [claims, setClaims] = useState({})
  const [userDoc, setUserDoc] = useState({})
  const [userDocSubmitting, setUserDocSubmitting] = useState(false)
  const [toastMessage, setToastMessage] = useState({})

  const register = async (email, password, isOnBoarding) => {
    return await createUserWithEmailAndPassword(auth, email, password)
      .then(userCredential => {
        setUser(userCredential.user || {})

        if (isOnBoarding) {
          const newUserDoc = {
            currentStep: onboardingSteps.step2,
            agreeToReceiveEmails: true,
          }
          createUserDoc(newUserDoc, userCredential.user.uid).then(res => {
            setUserDoc(newUserDoc)
            navigate("/onboarding/step-2")
          })
        } else {
          navigate(`/${process.env.GATSBY_VTC_SLUG}`)
        }
      })
      .catch(error => {
        const errorCode = error.code
        const errorMessage = error.message
        return { errorCode, errorMessage }
      })
  }

  const logout = async () => {
    await signOut(auth)
    setClaims({})
    setUserDoc({})
    setUser({})
    navigate("/login")
  }

  const login = async (email, password) =>
    signInWithEmailAndPassword(auth, email, password)
      .then(async userCredential => {
        setUser(userCredential.user)
        console.log("logged in")
        await getIdTokenResult(userCredential.user)
          .then(res => setClaims(res.claims))
          .catch(() => null)
      })
      .catch(error => {
        console.log(error)
        const errorCode = error.code
        const errorMessage = error.message
        return {
          errorCode,
          errorMessage,
        }
      })

  const api = (endpoint, data = {}) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    }

    if (user?.accessToken) {
      headers.Authorization = `Bearer ${user.accessToken}`
    }

    return axios({
      method: "post",
      url: `/api/${endpoint}`,
      headers,
      data,
    })
      .then(r => r.data)
      .catch(error => {
        return {
          isError: true,
          message: error.response?.data?.message || error.message,
        }
      })
  }

  const isFinishedOnboardingProcess = () => {
    if (!user) return false
    if (!userDoc.activationDate) return false

    return true
  }

  const onboardingProcessAccessWall = () => {
    if (!user) return

    if (userDoc.currentStep && !userDoc.activationDate) {
      let url = window.location.pathname
      const isHomepage = url === "/"
      if (!isHomepage && !url.includes("/onboarding/")) {
        navigate(`/onboarding/${userDoc.currentStep}`)
      }
    }
  }

  const canAccessSubscription = () => {
    if (!user?.uid) return false
    if (!!claims?.isAdmin) return true

    const now = new Date()
    const endOfCurrentCycle = userDoc.currentPeriodEnd
      ? new Date(
          (userDoc.currentPeriodEnd._seconds ||
            userDoc.currentPeriodEnd.seconds) * 1000,
        )
      : now

    if (!userDoc.isActivedTier && endOfCurrentCycle <= now) return false

    return true
  }

  const sendPwResetEmailToUser = async (email = null) =>
    sendPasswordResetEmail(auth, email || user.email)
      .then(() => true)
      .catch(() => false)

  const changeEmail = async email =>
    updateEmail(auth.currentUser, email)
      .then(() => true)
      .catch(error => {
        const errorCode = error.code
        const errorMessage = error.message
        return {
          errorCode,
          errorMessage,
        }
      })

  const reauthenticate = currentPassword => {
    const user = auth.currentUser
    const cred = EmailAuthProvider.credential(user.email, currentPassword)
    return reauthenticateWithCredential(user, cred)
      .then(userCredential => {
        setUser(userCredential.user)
        return true
      })
      .catch(() => false)
  }

  const changePassword = async (oldPassword, newPassword) => {
    const reauthenticated = await reauthenticate(oldPassword)
    if (!reauthenticated) {
      return "Current password is incorrect. Please retry."
    }

    return updatePassword(user, newPassword)
      .then(() => true)
      .catch(() => false)
  }

  const resetPassword = async (oobCode, newPassword) =>
    confirmPasswordReset(auth, oobCode, newPassword)
      .then(() => {
        return true
      })
      .catch(() => null)

  const changeUserMetadata = async (displayName = null, photoURL = null) =>
    updateProfile(auth.currentUser, {
      displayName: displayName || user?.displayName,
      photoURL: photoURL || user?.photoURL,
    })
      .then(() => {
        return true
      })
      .catch(error => {
        const errorCode = error.code
        const errorMessage = error.message
        return {
          errorCode,
          errorMessage,
        }
      })

  useEffect(() => {
    onAuthStateChanged(auth, user => {
      if (user) {
        getIdTokenResult(user)
          .then(res => {
            setClaims(res.claims)
            setUser(user)
            setBooting(false)
          })
          .catch(() => null)
      } else {
        setUser({})
        setBooting(false)
      }
    })
  }, [])

  useEffect(() => {
    if (!user.uid) return
    getUserDoc(user.uid)
      .then(userDoc => {
        setUserDoc(userDoc)
        // if current path are different with onboarding then redirect to uncompleted step
        // Put in here if we want to redirect on all page
        // Put login page if we want only redirect when user login
      })
      .catch(() => null)
  }, [user])

  const updateUserInfo = async values => {
    if (!user.uid) return
    try {
      setUserDocSubmitting(true)
      await updateUserDoc(values, user.uid)
      setUserDoc(await getUserDoc(user.uid))
    } catch (error) {
      console.error(error)
    }
    setUserDocSubmitting(false)
  }

  const removeUser = async () => {
    return deleteUser(user)
      .then(() => {
        return true
      })
      .catch(error => {
        const errorCode = error.code
        const errorMessage = error.message
        return {
          errorCode,
          errorMessage,
        }
      })
  }

  const showToast = (message, type) => {
    setToastMessage({ message, type })
    setTimeout(() => {
      setToastMessage({})
    }, 3000)
  }

  const refreshUserDoc = () => {
    if (!user.uid) return
    getUserDoc(user.uid)
      .then(userDoc => {
        setUserDoc(userDoc)
      })
      .catch(() => null)
  }

  const value = useMemo(
    () => ({
      user: {
        ...user,
        isAdmin: !!claims?.isAdmin,
      },
      booting,
      register,
      login,
      logout,
      sendPwResetEmailToUser,
      changeEmail,
      changePassword,
      resetPassword,
      changeUserMetadata,
      api,
      updateUserInfo,
      removeUser,
      userDocSubmitting,
      userDoc,
      setUserDoc,
      toastMessage,
      showToast,
      isFinishedOnboardingProcess,
      canAccessSubscription,
      onboardingProcessAccessWall,
      refreshUserDoc,
      reauthenticate,
    }),
    /* eslint-disable */
    [user, userDoc, claims, userDocSubmitting, toastMessage],
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
